import Layout from '../../components/v2/templates/Layout';
import React from 'react';
import { StoryblokPage } from '../../types';
import { graphql } from 'gatsby';
import useStoryblok from '../../lib/storyblok';
import Bloks from '../../components/atoms/Bloks';

const FraudFinderLandingPage: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;

  return (
    <Layout
      location={location}
      headerData={content.header}
      footerData={content.footer}
      headerVisibleButtonIndex={3}
      footerVisibleLinkIndex={1}
      hideNavMenu
    >
      <Bloks bloks={content.SEO} />
      <Bloks bloks={content.hero} />

      <Bloks className="lg:!py-32" bloks={content.sections} />

      <Bloks className="!py-32" bloks={content.footerCTA} />
    </Layout>
  );
};

export default FraudFinderLandingPage;

export const query = graphql`
  query FraudFinderLP {
    storyblokEntry(full_slug: { eq: "fraud-finder/fraud-finder-lp" }) {
      content
      name
    }
  }
`;
